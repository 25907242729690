.activeHeader {
  font-weight: bold;
  color: #0284c7;
  border-bottom: 2px solid #0284c7;
  border-radius: 0px;
  justify-content: center;
}
.activeNavBar {
  color: white;
  background-color: #ea580c;
  border-radius: 8px;
}
.activeUserItem {
  border-radius: 8px;
  color: #0284C7;
}

.activeAdminBar {
  color: white !important;
  background-color:#457AA8;
  border-radius: 8px;
}

.other {
  justify-content: center;
}
.other:hover {
  color: blue;
  text-decoration: underline;

  border-bottom: 1px solid #3b82f6;
  font-weight: bold;
  /* background-color: #f3f4f6; */
}

.test {
  text-decoration: underline;
  color: red;
}

.navbar-container .custom-active-link {
  border-bottom: 2px solid white; /* Une bordure blanche en bas pour indiquer l'état actif */
  color: white; /* Couleur du texte pour l'état actif */
}
