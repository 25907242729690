@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-size: 15px !important; /* Taille de police normale */
}
h1 {
  font-size: 28px !important;
}
h2 {
  font-size: 22px !important;
}
p {
  font-size: 18px !important;
  text-align: justify, center !important;
}

h3 {
  font-size: 13px !important;
}
h4 {
  font-size: 16px !important;
}
h5 {
  font-size: 15px !important;
}
h6 {
  font-size: 20px !important;
}
/* Appliquer le style uniquement en mode mobile */
@media screen and (max-width: 768px) {
  button {
    font-size: 10px !important; /* Priorise votre style sur la règle par défaut */
    margin-inline: 2px !important;
  }
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 15px !important;
  }
  p {
    font-size: 13px !important;
    text-align: justify,center !important;
  }
  h3 {
    font-size: 10px !important;
  }
  h5 {
    font-size: 10px !important;
  }
  h4 {
    font-size: 12px !important;
  }
  h6 {
    font-size: 14px !important;
  }
}

html {
  scroll-behavior:smooth;
}
