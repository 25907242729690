/* Pour Chrome, Safari et Edge */
/* Largeur et hauteur de la scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    
}

/* Piste de la scrollbar */
::-webkit-scrollbar-track {
    background: white; /* Couleur de fond de la piste */
    border-radius: 10px; /* Bordure de la piste */
    border: none;
}

/* Bouton de la scrollbar */
::-webkit-scrollbar-thumb {
    background: #aac6d8; /* Couleur du bouton */
    border-radius: 10px; /* Bordure du bouton */
    border: none;
}

/* Au survol de la scrollbar */
::-webkit-scrollbar-thumb:hover {
    background: #4d8cb3; /* Couleur du bouton au survol */
}
/* Bouton de la scrollbar (chevrons) */
::-webkit-scrollbar-button {
    display: block;
}

/* Chevrons de la scrollbar (haut et bas) */
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    background-color: #aac6d8;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #aac6d8 transparent;
}