@media (max-width: 426px) {
  .head-text {
    font-weight: 705;
    color: white;
    width: 203px;
    font-size: 20px !important;
    margin-top: 8rem;
    line-height: 22px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .sub-text {
    width: 183px;
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 427px) and (max-width: 767px) {
  .head-text {
    font-weight: 500;
    color: white;
    width: 303px;
    font-size: 24px !important;
    margin-top: 40px;
    line-height: 35.35px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .sub-text {
    width: 583px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .head-text {
    font-weight: 705;
    color: white;
    width: 603px;
    font-size: 34px !important;
    margin-top: 4rem;
    line-height: 35.35px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .sub-text {
    width: 323px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .head-text {
    font-weight: 700;
    color: white;
    width: 605px;
    font-size: 54px !important;
    margin-top: -40px;
    line-height: 65.35px;
    text-align: left;
    margin-bottom: 2rem;
  }

  .sub-text {
    width: 583px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-bottom: 2rem;
  }
}
